import { getData } from 'country-list'
import sortBy from 'lodash.sortby'

export const countryOptions = sortBy(getData(), ['name']).map(c => {
  return { value: c.code, label: c.name }
})

export const parseCountryCode = (countryCode: string) => {
  return countryOptions.find(country => country.value === countryCode)?.label || countryCode
}

export const fullCountryName = (country?: string | null) => {
  if (!country) return
  return countryOptions.find(c => c?.value === country)?.label
}

export const shortenCountryName = (countryName: string | undefined) => {
  if (countryName === 'United States of America' || countryName === 'United States') {
    return 'USA'
  }
  if (countryName === 'United Kingdom of Great Britain and Northern Ireland') {
    return 'UK'
  }
  return countryName
}

export const priorityCountryOptions = (userCountryCode?: string) => {
  const defaultPriorityCountries = [
    { value: 'AU', label: 'Australia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' }
  ]

  if (!userCountryCode) {
    return defaultPriorityCountries
  } else {
    if (['AU', 'NZ', 'GB', 'US'].includes(userCountryCode)) {
      return defaultPriorityCountries
    }
    const userCountryOption = countryOptions.find(c => c.value === userCountryCode)

    return userCountryOption ? [userCountryOption] : defaultPriorityCountries
  }
}

export function getClosestCountryCodeFromTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (timeZone.startsWith('US/') || timeZone.startsWith('America/')) {
    return 'US'
  } else if (timeZone.startsWith('Europe/') || timeZone.startsWith('GB/')) {
    return 'GB'
  } else if (timeZone.startsWith('Pacific/Auckland') || timeZone.startsWith('Pacific/Chatham')) {
    return 'NZ'
  } else if (timeZone.startsWith('Australia/')) {
    return 'AU'
  }
}

export const statesAndRegionsAUOptions = [
  {
    options: [
      {
        label: 'Australia wide (all states and regions)',
        value: 'AU'
      }
    ]
  },
  {
    options: [
      { label: 'NSW Metro', value: '1' },
      { label: 'NSW Regional (includes ACT)', value: '2' },
      { label: 'Norfolk Island', value: '3' }
    ]
  },
  {
    options: [
      { label: 'VIC Metro', value: '4' },
      { label: 'VIC Regional', value: '5' }
    ]
  },
  {
    options: [
      { label: 'QLD Metro', value: '6' },
      { label: 'QLD Near Country', value: '7' },
      { label: 'QLD Far Country', value: '8' },
      { label: 'QLD Far North', value: '9' }
    ]
  },
  {
    options: [
      { label: 'SA Metro', value: '10' },
      { label: 'SA Regional', value: '11' }
    ]
  },
  {
    options: [
      { label: 'WA Metro', value: '12' },
      { label: 'WA Mid', value: '13' },
      { label: 'WA North', value: '14' }
    ]
  },
  {
    options: [{ label: 'NT', value: '15' }]
  },
  {
    options: [{ label: 'TAS', value: '16' }]
  }
]
// react-select does not support multiple levels of optgroups
// so we have to add a flag `isSubRegion` to indicate subgroup indention
export const statesAndRegionsNZOptions = [
  {
    options: [{ label: 'New Zealand wide (all regions)', value: 'NZ' }]
  },
  {
    options: [
      { label: 'North Island', value: 'NZ-NORTH' },
      { label: 'Auckland', value: '17', isSubRegion: true },
      { label: 'Bay of Plenty', value: '18', isSubRegion: true },
      { label: 'Gisborne', value: '20', isSubRegion: true },
      { label: 'Hawke’s Bay', value: '21', isSubRegion: true },
      { label: 'Manawatu-Wanganui', value: '22', isSubRegion: true },
      { label: 'Northland', value: '25', isSubRegion: true },
      { label: 'Taranaki', value: '28', isSubRegion: true },
      { label: 'Waikato', value: '30', isSubRegion: true },
      { label: 'Wellington', value: '31', isSubRegion: true }
    ]
  },
  {
    options: [
      { label: 'South Island', value: 'NZ-SOUTH' },
      { label: 'Canterbury', value: '19', isSubRegion: true },
      { label: 'Marlborough', value: '23', isSubRegion: true },
      { label: 'Nelson', value: '24', isSubRegion: true },
      { label: 'Otago', value: '26', isSubRegion: true },
      { label: 'Southland', value: '27', isSubRegion: true },
      { label: 'Tasman', value: '29', isSubRegion: true },
      { label: 'West Coast', value: '32', isSubRegion: true }
    ]
  }
]
